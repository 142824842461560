import { createElement, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getInputValueByType } from "src/providers/inputProvider";
import { getAllPdtInfos, getDataStructure } from "src/services/api";
import { DataStructureType } from "src/types/datastructure";
import { Product } from "src/types/product";
import { getInfoByTab, setPdtInfos } from "src/services/api";
import useNotifications from "src/pages/Notifications/useNotifications";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDatastructureAction } from "src/features/datastructure/datastructure";

const useProduct = () => {
    const [formData, setFormData] = useState<any>();
    const [steps, setSteps] = useState<string[]>();
    const [dataStructureContainer, setDataStructureContainer] = useState<DataStructureType>();
    const [dataTypes, setDataTypes] = useState<any>();
    const [activeStep, setActiveStep] = useState(0);
    const [mandatoryParams, setMandatoryParams] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [toDelete, setToDelete] = useState<any>();
    const [lastApiCallTime, setLastApiCallTime] = useState(0);
    const [encartEdit, setEncartEdit] = useState<any>();
    const navigate = useNavigate();
    const location = useLocation();

    // Données du catalogs visionné
    const [refciale, setRefciale] = useState<string>();
    const params = useParams();

    const { addNotification } = useNotifications();

    const datastructureCached = useSelector((state: any) => state.datastructure);

    const dispatch = useDispatch();

    // Constructor
    useEffect(() => {
        if (params[Object.keys(params)[1]] != "") {
            // Dirty but allows to pass in url refciale with slashes in them
            let tempRefciale = "";
            Object.keys(params).forEach((a: any, id: number) => (tempRefciale += params[a] + (id != Object.keys(params).length - 1 ? "/" : "")));
            setRefciale(tempRefciale);
        } else {
            setRefciale(params.refciale);
        }
        const sentActiveStep = new URLSearchParams(location.search).get("activeStep");
        if (sentActiveStep) {
            try {
                const parsedSentActiveStep = parseInt(sentActiveStep);
                setActiveStep(parsedSentActiveStep);
            } catch (e: any) {
                // pass for now
            }
        }
    }, []);

    // After constructor
    useEffect(() => {
        if (!refciale) return;

        // If we already have datastructure cached, don't call api anymore

        if (datastructureCached.datastructure) {
            let temp = { ...datastructureCached.datastructure };
            temp.datastructure.filter((elem: any) => elem.related_data != "CARTOUCHE");
            setDataStructureContainer(temp);
            setDataTypes({
                dataUnits: temp.dataUnits,
                dataMediaTypes: temp.dataMediaTypes,
                dataAvantages: temp.dataAvantages,
            });
            // Gets mandatory params to check if all data is filled before sending update
            let tempMandatoryParams: Array<any> = [];
            temp.datastructure.forEach((ds: any, dsId: number) => {
                tempMandatoryParams.push([]);
                ds.fields.forEach((_: any) => {
                    if (_.mandatory && _.field_type !== "boolean") {
                        tempMandatoryParams[dsId].push(_.field_name);
                    }
                });
            });
            setMandatoryParams(tempMandatoryParams);
        } else {
            // Gathers all datastructures for tabs
            getDataStructure().then((ds) => {
                let dss = ds.data;
                // Gets rid of the CARTOUCHE tab
                dss.datastructure = dss.datastructure.filter((elem: any) => elem.related_data != "CARTOUCHE");
                setDataStructureContainer(dss);

                dispatch(setDatastructureAction(dss));

                setDataTypes({
                    dataUnits: dss.dataUnits,
                    dataMediaTypes: dss.dataMediaTypes,
                    dataAvantages: dss.dataAvantages,
                });

                // Gets mandatory params to check if all data is filled before sending update
                let tempMandatoryParams: Array<any> = [];
                dss.datastructure.forEach((ds: any, dsId: number) => {
                    tempMandatoryParams.push([]);
                    ds.fields.forEach((_: any) => {
                        if (_.mandatory && _.field_type !== "boolean") {
                            tempMandatoryParams[dsId].push(_.field_name);
                        }
                    });
                });
                setMandatoryParams(tempMandatoryParams);
            });
        }
    }, [refciale]);

    // Gets values from current tab on change
    useEffect(() => {
        if (!dataStructureContainer) return;

        getAllPdtInfos(refciale!).then((data) => {
            var array: any[] = [];
            data.forEach((value: any, id: number) => array.push(value?.data));

            array.forEach((tabPdtInfo: any, index: number) => {
                if (!tabPdtInfo?.results) {
                    if (dataStructureContainer.datastructure[index].editType == "record") {
                        array[index] = {
                            totalResult: 1,
                            results: [createDefaultObjectForData(index)],
                        };
                    } else {
                        array[index] = {
                            totalResult: 0,
                            results: [],
                        };
                    }
                }
            });

            setFormData(array);
        });
        let tempSteps: any[] = [];
        dataStructureContainer.datastructure.forEach((item, index) => {
            tempSteps.push(item.collection_name);
        });
        setSteps(tempSteps);
    }, [dataStructureContainer]);

    const setInputState = async (indexDS: number, name: string, value: any, indexEncart: number) => {
        let temp = { ...formData };
        temp[indexDS].results[indexEncart][name] = value;
        setFormData(temp);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setActiveStep(newValue);

    const updateProduct = () => {
        let missingFields: Array<any> = [];

        mandatoryParams[activeStep].forEach((mandatoryParamId: string) => {
            formData[activeStep].results.forEach((val: any, id: number) => {
                if (!val[mandatoryParamId] && val[mandatoryParamId] !== false) {
                    missingFields.push(mandatoryParamId);
                }
            });
        });

        if (missingFields.length) {
            addNotification("warning", "Certains champs ne sont pas remplis", `Afin d'update le produit, il vous faut remplir ces champs : ${missingFields.join(", ")}`, 5000);
            return;
        }

        let formatMismatched: { status: boolean; expectedType: string | string[] | undefined; fieldName: string | undefined }[] = [];
        Object.entries(formData[activeStep].results[0]).forEach(([key, value]: [string, any]) => {
            let fieldParams = dataStructureContainer!.datastructure[activeStep].fields.find((field) => field.field_name === key);
            let formatTest = {
                status: false,
                expectedType: fieldParams?.format_control,
                fieldName: fieldParams?.field_name,
            };
            if (value !== "" && value) {
                switch (fieldParams?.format_control) {
                    case "float":
                        formatTest.status = /^[0-9.]+$/gm.test(value);
                        break;
                    case "integer":
                        formatTest.status = /^[0-9]+$/gm.test(value);
                        break;

                    case "email":
                        formatTest.status = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                        break;

                    case "url":
                        formatTest.status = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(value);
                        break;

                    case "DD/MM/YYYY":
                        formatTest.status = !!Date.parse(value);
                        break;

                    case "string":
                        formatTest.status = value === value.toString() || value.every((_: any) => typeof _ === "string");
                        break;

                    default:
                        formatTest.status = true;
                }

                if (!formatTest.status) {
                    formatMismatched.push(formatTest);
                }
            }
        });

        if (formatMismatched.length) {
            addNotification(
                "danger",
                "Mauvais format des inputs",
                createElement(
                    "p",
                    null,
                    formatMismatched.map((formatTestResult) => {
                        return createElement("div", null, `Le champ ${formatTestResult.fieldName} est du type ${formatTestResult.expectedType}`);
                    })
                ),
                5000
            );
        } else {
            const currentDataStructure = dataStructureContainer!.datastructure[activeStep];
            formData[activeStep].results.forEach((item: any) => {
                let action = "CREATE";
                if (item.ID) {
                    action = "UPDATE";
                }

                setPdtInfos(item, currentDataStructure.crud_api.find((e: any) => e.action === action)!.endpoint, currentDataStructure.type)
                    .then((data: any) => {
                        if (data.data) {
                            addNotification("success", "Produit mis à jour", "Le produit a bien été mis à jour !");
                        }
                    })
                    .catch((data: any) => {
                        addNotification("danger", "Une erreur est survenue", `Voici l'erreur rencontrée : ${data.response.data.error}`, 5000);
                    });
            });

            return;
        }
    };

    const createDefaultObjectForData = (step: any) => {
        let result: any = {
            ID: "",
            import_date: Date.now(),
            upd_date: Date.now(),
            crea_date: Date.now(),
        };

        const fields = dataStructureContainer!.datastructure[step].fields.filter((e: any) => e.field_show);
        fields.forEach((field) => {
            switch (field.field_type) {
                case "string":
                case "email":
                case "autocomplete":
                    result[field.field_name] = "";
                    break;
                case "boolean":
                    result[field.field_name] = false;
                    break;
                case "array":
                    result[field.field_name] = [];
                    break;
                default:
                    result[field.field_name] = "";
                    break;
            }
        });

        return result;
    };

    const createDefaultObjectForActiveStep = () => {
        let result: any = {
            ID: "",
            import_date: Date.now(),
            upd_date: Date.now(),
            crea_date: Date.now(),
        };

        const fields = dataStructureContainer!.datastructure[activeStep].fields.filter((e: any) => e.field_show);
        fields.forEach((field) => {
            switch (field.field_type) {
                case "string":
                case "email":
                case "autocomplete":
                    result[field.field_name] = "";
                    break;
                case "boolean":
                    result[field.field_name] = false;
                    break;
                case "array":
                    result[field.field_name] = [];
                    break;
                default:
                    result[field.field_name] = "";
                    break;
            }
        });

        return result;
    };

    const addEncart = (indexDs: number, collectionName: string) => {
        let tempFormData = { ...formData };
        let defaultObject: any = createDefaultObjectForActiveStep();

        defaultObject["REFCIALE"] = refciale;

        tempFormData[indexDs].results.push(defaultObject);
        tempFormData[indexDs].totalResult += 1;

        // Auto open modal on encart created

        setEncartEdit([activeStep, tempFormData[indexDs].totalResult - 1]);
        setOpen(true);
        setIsEdit(false);

        setFormData(tempFormData);
    };

    const preDeleteEncart = (indexDs: number, indexEncart: number) => {
        setToDelete([indexDs, indexEncart]);
        setDeleteModalOpen(true);
    };

    const deleteEncart = () => {
        if (!toDelete) return;

        const indexDs = toDelete[0];
        const indexEncart = toDelete[1];

        let tempFormData = { ...formData };

        const currentDataStructure = dataStructureContainer!.datastructure[activeStep];

        const prevId = tempFormData[indexDs].results[indexEncart].ID;
        tempFormData[indexDs].results.splice(indexEncart, 1);
        if (tempFormData[indexDs].totalResult) tempFormData[indexDs].totalResult -= 1;
        tempFormData[indexDs].results.forEach((result: any, index: number) => {
            tempFormData[indexDs].results[index] = result;
        });
        setFormData(tempFormData);

        if (!prevId) {
            return;
        }

        setPdtInfos({ ID: prevId }, currentDataStructure.crud_api.find((e: any) => e.action === "DELETE")!.endpoint)
            .then((data: any) => {
                if (data.data) {
                    notifySuccessAndClose(setDeleteModalOpen);
                    setDeleteModalOpen(false);
                }
            })
            .catch((data: any) => {
                addNotification("danger", "Une erreur est survenue", `Voici l'erreur rencontrée : ${data.response.data.error}`, 5000);
            });
    };

    const goBack = () => {
        navigate(-1);
    };

    const goTop = () => {
        window.scrollTo(0, 0);
    };

    const updatePdtInfosUpdated = (data: any, encart: any, collection: string, type: string) => {
        const uploadedPdt = data.data.record;
        let tempFormData = { ...formData };
        const updatedEncart = tempFormData[activeStep].results.find((res: any) => res.ID === encart.ID);

        if (type == "media") {
            updatedEncart.URL = uploadedPdt.URL;
            updatedEncart.URLT = uploadedPdt.URLT;
        }

        if (uploadedPdt.ID) {
            updatedEncart.ID = uploadedPdt.ID;
        }

        setFormData(tempFormData);
        notifySuccessAndClose();
    };

    const saveEncart = (encart: any, collection: string) => {
        const currentDataStructure = dataStructureContainer!.datastructure[activeStep];
        if (areMandatoryFilled(encart, currentDataStructure.type)) {
            let action = "CREATE";
            if (encart.ID) {
                action = "UPDATE";
            }

            setPdtInfos(encart, currentDataStructure.crud_api.find((e: any) => e.action === action)!.endpoint, currentDataStructure.type)
                .then((data: any) => {
                    if (data.data) {
                        updatePdtInfosUpdated(data, encart, collection, currentDataStructure.type);
                    }
                })
                .catch((data: any) => {
                    addNotification("warning", "Certains champs sont en erreur...", `Voici l'erreur rencontrée : ${data.response.data.error}`, 5000);
                });
        }
    };

    const areMandatoryFilled = (encart: any, type: string = "") => {
        let missingFields: Array<any> = [];

        mandatoryParams[activeStep].forEach((mandatoryParamId: string) => {
            if (!encart[mandatoryParamId] && encart[mandatoryParamId] !== false) {
                missingFields.push(mandatoryParamId);
            }
        });

        if (missingFields.length) {
            addNotification("warning", "Certains champs ne sont pas remplis", `Afin d'update le produit, il vous faut remplir ces champs : ${missingFields.join(", ")}`, 5000);
            return false;
        }

        return true;
    };

    const deleteEncartSkeleton = (indexDS: number, index: number) => {
        let tempFormData = { ...formData };
        if (tempFormData[indexDS].totalResult !== 0) {
            tempFormData[indexDS].totalResult--;
        }

        tempFormData[indexDS].results.splice(index, 1);

        setFormData(tempFormData);
    };

    const notifySuccessAndClose = (customSetState: any = null) => {
        addNotification("success", "Produit mis à jour", "Le produit a bien été mis à jour !");
        customSetState ? customSetState(false) : setOpen(false);
    };

    return {
        dataStructureContainer,
        steps,
        activeStep,
        handleChange,
        formData,
        setInputState,
        dataTypes,
        updateProduct,
        deleteEncart,
        addEncart,
        goBack,
        goTop,
        saveEncart,
        open,
        setOpen,
        preDeleteEncart,
        deleteModalOpen,
        setDeleteModalOpen,
        encartEdit,
        setEncartEdit,
        deleteEncartSkeleton,
        isEdit,
        setIsEdit,
    };
};

export default useProduct;
