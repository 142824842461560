import { ButtonBase } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";

type DropzoneProps = {
    name: string;
    description: string;
    state: any;
    handleState: any;
    indexParent: any;
    indexField: any;
};

const previewStyle = {
    maxWidth: "150px",
};

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const Dropzone = (props: DropzoneProps) => {
    const [files, setFiles] = useState<any>([]);
    const { name, state, handleState, indexParent, indexField } = props;
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "image/svg+xml": [],
            "application/msword": [],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
            "application/vnd.ms-excel": [],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
            "application/vnd.ms-excel.sheet.macroEnabled.12": [],
            "application/pdf": [],
            "application/vnd.ms-powerpoint": [],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
            "application/acad": [".dwg"],
            "image/vnd.dwg": [".dwg"],
            "image/x-dwg": [".dwg"],
        },
        maxSize: 10000000,
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
            handleState(indexParent, name, acceptedFiles[0], indexField);
        },
        maxFiles: 1,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const thumbs = files.map((file: any) => (
        <div key={file.name}>
            <div>
                <img
                    src={file.preview}
                    // Revoke data uri after image is loaded
                    style={previewStyle}
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                />
            </div>
        </div>
    ));

    const deleteCurrentImage = () => {
        handleState(indexParent, name, undefined, indexField);
        setFiles([]);
    };

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <section className="container">
            {files.length > 0 && (
                <ButtonBase onClick={() => deleteCurrentImage()} sx={{ marginBottom: 2 }}>
                    <DeleteIcon sx={{ fontSize: 35 }} />
                </ButtonBase>
            )}
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>{thumbs}</aside>
        </section>
    );
};

export default Dropzone;
